<template>
  <div>
    <!-- begin::Sticky Toolbar -->
    <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <li
        v-b-tooltip.hover.left="'Update Products from Store'"
        class="nav-item mb-2"
      >
        <a
          class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
          @click="pullProducts('woo')"
        >
          <i class="flaticon2-rocket-1"></i>
        </a>
      </li>
      <li
        v-b-tooltip.hover.left="'Update Products from Edara'"
        class="nav-item"
      >
        <a
          class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
          @click="pullProducts('edara')"
        >
          <i class="flaticon2-delivery-truck"></i>
        </a>
      </li>
    </ul>
    <!-- end::Sticky Toolbar -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "KTStickyToolbar",
  methods: {
    pullProducts(type) {
      ApiService.get(`/products/${type}/pull`).then(({ data }) => {
        this.makeToast("success", data.message);
      });
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Orders`,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
